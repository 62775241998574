import React, { Component } from 'react';
import M from 'materialize-css';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
//import MyImage from './MyImage';

export class Project extends Component {
    static displayName = Project.name;

    constructor(props) {
        super(props);
        this.drawData = this.drawData.bind(this);
        this.initCloseButton = this.initCloseButton.bind(this);
        this.handleOnClose = this.handleOnClose.bind(this);

        this.state = {
            id: null,
            item: null,
            loading: true
        };
    }

    componentDidMount() {

        const queryParams = new URLSearchParams(this.props.location.search);
        const id = queryParams.get('id');
        this.populateData(id);
    }

    componentDidUpdate() {
        let elems = document.querySelectorAll('.materialboxed');
        M.Materialbox.init(elems);

        this.initCloseButton();
        window.scrollTo(0, 0);
    }

    async populateData(id) {
        try {
            const response = await fetch(`resume/project?id=${id}`);
            const item = await response.json();
            this.setState({
                id: id,
                item: item,
                loading: false
            });
        } catch (error) {
            this.setState({
                id: id,
                item: null,
                loading: false
            });
        }
    }

    initCloseButton() {
        let elems = document.querySelectorAll('.fixed-action-btn');
        M.FloatingActionButton.init(elems, {
            direction: 'top'
        });
    }

    handleOnClose(event) {
        event.preventDefault();
        if (this.props.onCloseProject)
            this.props.onCloseProject();
    }

    drawData(item, id) {

        let title = (
            <h3>{item.name}</h3>
        );

        let subtitle = (
            <p>{item.description}</p>
        );

        let lines = item.text.map((item, index) => {
            let id = `line_${index}`;
            return <p key={id}>{item}<br /></p>
        });

        let links = item.links.map((item, index) => {
            let id = `link_${index}`;
            return <div key={id}><span>{`${item.name}: `} <br /> <a href={item.url} target="_blank">{`${item.url}`}</a> </span> <br /></ div>
        });

        let images = (
            <p>No images available.</p>
        );

        let closeButton = (
            <div className="fixed-action-btn">
                <NavLink tag={Link} to="/projects" className="btn-floating btn red"><i className="material-icons">close</i></NavLink>
            </div>
        );

        let closeButton_old = (
            <div className="fixed-action-btn">
                <a className="btn-floating btn red" onClick={this.handleOnClose}>
                    <i className="material-icons">close</i>
                </a>
            </div>
        );

        let closeBtn = (
            <a className="btn-floating btn waves-effect waves-light red"><i className="material-icons">close</i></a>
        );

        if (item.images) {
            images = item.images.map((img, index) => {
                let id = `img_${index}`;
                // width="250" 
                return (
                    <div key={id} className="col s6 m4 l3">
                        <span>
                            {/*<MyImage src={img.url} className="responsive-img materialboxed z-depth-3" data-caption={img.name} />*/}
                            {/*loading="lazy"*/}
                            <img className="responsive-img materialboxed z-depth-3" data-caption={img.name} src={img.url} />
                            <br />
                        </span>
                    </div>
                );
            })
        }

        let temp = (
            <div className="row">
                <div className="col s11">
                    {title}
                </div>
                <div className="col s1">
                    {closeBtn}
                </div>
            </div>
        );

        let nav = (
            <nav>
                <div className="nav-wrapper transparent">
                    <div className="col s12">
                        <NavLink tag={Link} to="/projects" className="breadcrumb">Projects</NavLink>
                        <a className="breadcrumb">{item.name}</a>
                    </div>
                </div>
            </nav>
        );

        if (item.links && item.links.length > 0) {
            return (
                <div key={id} >
                    {nav}
                    {title}
                    {closeButton}
                    {subtitle}
                    <div className="row">
                        <div className="col s12 m8">
                            {lines}
                        </div>
                        <div className="col s12 m4">
                            {links}
                        </div>
                    </div>
                    <div className="row">
                        {images}
                    </div>
                </div>
            );
        } else {
            return (
                <div key={id} >
                    {nav}
                    {title}
                    {closeButton}
                    {subtitle}
                    <div className="row">
                        <div className="col s12 m12">
                            {lines}
                        </div>
                    </div>
                    <div className="row">
                        {images}
                    </div>
                </div>
            );
        }
    }

    render() {

        if (this.state.loading) {
            return <>
                <p>loading...</p>
            </>
        }

        let item = this.state.item;
        let id = this.state.id;

        if (id && !item) {
            return <>
                <p>{`Project '${id}' not found!`}</p>
                <p>You can check my projects <NavLink tag={Link} to="/projects">here</NavLink></p>
            </>
        }

        if (id && item) {
            return this.drawData(item, id);
        }

        return <p>loading...!!!</p>
    }
}

