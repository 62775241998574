var rLocalStorage = {
    set: function (key, value) {
        if (!key)
            return;

        if (typeof value === 'object')
            value = JSON.stringify(value);

        window.localStorage.setItem(key, value);
    },
    get: function (key) {
        if (!key)
            return;

        var value = localStorage.getItem(key);
        if (!value)
            return;

        if (value[0] === '{' || value[0] === '[')
            value = JSON.parse(value);

        return value;
    }
};

var rSessionStorage = {
    set: function (key, value) {
        if (!key)
            return;

        if (typeof value === 'object')
            value = JSON.stringify(value);

        window.sessionStorage.setItem(key, value);
    },
    get: function (key) {
        if (!key)
            return;

        var value = sessionStorage.getItem(key);
        if (!value)
            return;

        if (value[0] === '{' || value[0] === '[')
            value = JSON.parse(value);

        return value;
    }
};


export const gf = {
    clearAll: function () {
        window.localStorage.clear();
        window.sessionStorage.clear();
    },
    clearLocalStorage: function () {
        window.localStorage.clear();
    },
    getTheme: function () {
        return rLocalStorage.get('theme');
    },
    setTheme: function (value) {
        rLocalStorage.set('theme', value);
    },
    getThemeCustomized: function () {
        return rLocalStorage.get('theme-custom');
    },
    setThemeCustomized: function (value) {
        rLocalStorage.set('theme-custom', value);
    }
};

