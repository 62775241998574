import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

export class ContactMe extends Component {
    static displayName = ContactMe.name;

    constructor(props) {
        super(props);
        this.getForm = this.getForm.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.send = this.send.bind(this);
        this.restartForm = this.restartForm.bind(this);

        this.state = {
            serverResponse: null,
            name: '',
            email: '',
            subject: '',
            body: ''
        };
    }

    componentDidMount = () => window.scrollTo(0, 0);

    async send(e) {
        e.preventDefault();

        try {
            if (!this.state.name) {
                // TODO: Check Class for error on inputs
                document.querySelector('#name').classList.add('error');
                document.querySelector('#name').focus();
                throw new Error("You should write your name.");
            }

            if (!this.state.email) {
                document.querySelector('#email').classList.add('error');
                document.querySelector('#email').focus();
                throw new Error("You should write your email.");
            }

            if (!this.state.subject) {
                document.querySelector('#subject').classList.add('error');
                document.querySelector('#subject').focus();
                throw new Error("You should write a subject.");
            }

            if (!this.state.body) {
                document.querySelector('#body').classList.add('error');
                document.querySelector('#body').focus();
                throw new Error("You should write a message.");
            }

            // TODO: Validate email

            // TODO: Validate lenght

            // send message to the server
            let response = await fetch('resume/sendMessage', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: this.state.name,
                    email: this.state.email,
                    subject: this.state.subject,
                    body: this.state.body
                })
            });

            let data = await response.json();

            this.setState({
                serverResponse: data,
                name: '',
                email: '',
                subject: '',
                body: ''
            });

            this.forceUpdate();

        } catch (ex) {
            // TODO: Show the error to the user
            alert(ex);
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    restartForm(e) {
        e.preventDefault();
        this.setState({
            serverResponse: null,
            name: '',
            email: '',
            subject: '',
            body: ''
        });

        this.forceUpdate();
    }

    getForm() {
        return (
            <>
                <div className="row">
                    <form className="col s12 m11 l8" onSubmit={this.send}>
                        <div className="row">
                            <div className="input-field col s12">
                                <i className="material-icons prefix">account_circle</i>
                                <input onChange={this.handleInputChange} id="name" name="name" type="text" className="validate" value={this.state.name} required />
                                <label htmlFor="name">Name</label>
                                <span className="helper-text" data-error="This field is mandatory!" data-success=""></span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="input-field col s12">
                                <i className="material-icons prefix">email</i>
                                <input onChange={this.handleInputChange} id="email" name="email" type="email" className="validate" value={this.state.email} required />
                                <label htmlFor="email">Email</label>
                                <span className="helper-text" data-error="This field is mandatory!" data-success=""></span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="input-field col s12">
                                <i className="material-icons prefix">subject</i>
                                <input onChange={this.handleInputChange} id="subject" name="subject" type="text" className="validate" value={this.state.subject} required />
                                <label htmlFor="subject">Subject (New App or Website, New formal job, etc)</label>
                                <span className="helper-text" data-error="This field is mandatory!" data-success=""></span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="input-field col s12">
                                <i className="material-icons prefix">mode_edit</i>
                                <textarea onChange={this.handleInputChange} id="body" name="body" className="materialize-textarea validate" minLength="20" data-length="500" value={this.state.body} required></textarea>
                                <label htmlFor="body">Message</label>
                                <span className="helper-text" data-error="This field is mandatory!" data-success=""></span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s11 rigth-align right">
                                <button className="btn btn-primary waves-effect waves-light rigth-align right">
                                    Send
                                    <i className="material-icons right">send</i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
    }

    render() {

        if (!this.state.serverResponse) {

            return (
                <div>
                    <h3>Contact me</h3>

                    <p>Use this form to send me a short message.</p>

                    {this.getForm()}
                </div>
            );

        } else {

            return (
                <div>
                    <h3>Contact me</h3>

                    <p>Thanks for reaching me!</p>
                    <p>I'll be in touch with you as soon as possible.</p>

                    <p>Meanwhile, you can continue checking my <NavLink tag={Link} to="/projects">projects</NavLink> or leave <a href="#!" onClick={this.restartForm}>another message</a>.</p>

                    <p>Additionally, you can download my Resume from <a href="#!" onClick={this.restartForm}>here</a>.</p>
                </div>
            );

        }
    }
}