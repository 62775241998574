import React, { Component } from 'react';
import M from 'materialize-css';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

export class ProjectCard extends Component {
    static displayName = ProjectCard.name;

    constructor(props) {
        super(props);

        this.drawCard = this.drawCard.bind(this);
        this.getCardContent = this.getCardContent.bind(this);
        this.showCarousel = this.showCarousel.bind(this);
    }

    drawCard(item, id) {

        //let linkForImages = (
        //    <div className="card-action">
        //        <a href="#!" onClick={this.showCarousel}>Screenshots</a>
        //    </div>);

        let linkForMoreDetails = (
            <div className="card-action">
                <NavLink tag={Link} to={"/project?id=" + item.id}>More details</NavLink>
            </div>);

        return (
            <div className="card sticky-action" key={id}>
                <div className="card-image">
                    <img className="activator" src={item.image} loading="lazy" />
                </div>
                <div className="card-content">
                    <span className="card-title activator">{item.name}<i className="material-icons right">more_vert</i></span>
                    <p className="truncate">{item.description}</p>
                </div>
                <div className="card-reveal">
                    <span className="card-title">{item.name}<i className="material-icons right">close</i></span>
                    <p>{this.getCardContent(item)}</p>
                </div>
                {linkForMoreDetails}
            </div>
        );
    }

    getCardContent(item) {

        if (item.text)
            if (item.text.length > 0)
                return <span>{item.text[0]}</span>;

        return <span>No text found.</span>;
    }

    getCardContent_nooooooo(item) {

        let lines = item.text.map((item, index) => {
            let id = `line_${index}`;
            return <span key={id}>{item}<br /></span>
        });

        let links = item.links.map((item, index) => {
            let id = `link_${index}`;
            return <span key={id}>{`${item.name}: `} <a href={item.url} target="_blank">{`${item.url}`}</a> </span>
        });

        //let screenshots = '';
        //if (item.images) {
        //    screenshots = <a className="waves-effect waves-light btn" onClick={this.showCarousel}>Screenshots</a>
        //}

        //    <br />
        //    <span>{screenshots}</span>
        return (
            <>
                <span>{lines}</span>
                <br />
                <span>{links}</span>
                <br />

            </>
        );
    }

    getModalBody_only_images_noooo(item) {
        //let imgs = item.images.map((item, index) => {
        //    return `<a class="carousel-item" href='#img${index}!'><img src='${item.url}'></a>`;
        //});

        //  
        let imgs = '';
        for (let i = 0; i < item.images.length; i++) {
            imgs += `<img class="materialboxed" width="200" data-caption='${item.images[i].name}' src='${item.images[i].url}'>`;
        }

        //  class="row"><div class="col s12 l4 m6"
        // </div>
        return `<div>${imgs}</div>`;
    }

    getModalBody_carousel_noooo(item) {
        let imgs = '';
        for (let i = 0; i < item.images.length; i++) {
            imgs += `<a class="carousel-item" href='#img${i}!'><img src='${item.images[i].url}'></a>`;
        }

        return `<div class="carousel">${imgs}</div>`;
    }

    getModalBody(item) {
        let imgs = '';
        for (let i = 0; i < item.images.length; i++) {
            // class="materialboxed" width="400" 
            imgs += `<li><img class="responsive-img materialboxed" width="200" src='${item.images[i].url}' /></li>`;
        }

        //return `<div class="slider fullscreen"><ul class="slides">${imgs}</ul></div>`;
        return `<div class="slider"><ul class="slides">${imgs}</ul></div>`;
    }

    showCarousel(event) {

        event.preventDefault();

        //let id = event.target.dataset.id;
        //console.log(id);

        let item = this.props.item;
        let title = item.name;
        let body = this.getModalBody(item);

        document.getElementById('modal-full-title').innerHTML = title;
        document.getElementById('modal-full-body').innerHTML = body;

        let modal = document.getElementById('modal-full');
        M.Modal.init(modal, {
            onOpenEnd: () => {

                //let carouselElems = document.querySelectorAll('.carousel');
                //M.Carousel.init(carouselElems, {
                //    duration: 1000,
                //    indicators: true
                //});

                let slidsElems = document.querySelectorAll('.slider');
                M.Slider.init(slidsElems);

                let elems = document.querySelectorAll('.materialboxed');
                M.Materialbox.init(elems);
            }
        });

        let instance = M.Modal.getInstance(modal);
        instance.open();
    }

    render() {
        return this.drawCard(this.props.item, this.props.id);
    }
}

