import React, { Component } from 'react';
import me from './../imgs/me-03-small.jpg';
import { theme } from '../js/theme';

export class BasicData extends Component {
    static displayName = BasicData.name;

    constructor(props) {
        super(props);
        this.state = {
            themeMode: theme.getTheme()
        };

        theme.onChange = () => {
            this.setState({ themeMode: theme.getTheme() });
        };
    }


    render() {

        let theme = this.state.themeMode;

        /*<div className={`card-panel z-depth-0 ${theme}`}>*/
        /*<div className="card-panel z-depth-0">*/

        return (
            <div>
                <div className={`card-panel z-depth-0 ${theme}`}>
                    <div className="row">
                        <div className="col s3 m3">
                            <img src={me} alt="" className="circle responsive-img z-depth-3" />
                        </div>
                        <div className="col s9 m9">
                            <span>
                                C# developer for Windows, Web and Mobiles apps with some experience in Android using Java and Kotlin.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}