import React, { Component } from 'react';
import { Summary } from './Summary';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

export class Home extends Component {
    static displayName = Home.name;

    render() {

        // devops
        // pass: 7px2fln6uhygzgyfhxcbbahidtodluk2dap32cknlkzk43vmxmjq

        return (
            <>
                <div className="row">
                    <div className="col s12">
                        <Summary />
                    </div>
                </div>
                <br />
                <br />
                <div className="row icon-container">
                    <div className="col s12">
                        <div className="col s12 m3 l3">
                            <div className="center home-icon">
                                <NavLink tag={Link} to="/projects">
                                    <div className="icon-preview">
                                        <i className="material-icons">important_devices</i>
                                        <h5>Projects</h5>
                                        <p className="center"><strong>Explore my work:</strong> Take a look at my past projects and get a sense of my skills and experience as a software developer.</p>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                        <div className="col s12 m3 l3">
                            <div className="center home-icon">
                                <NavLink tag={Link} to="/resume">
                                    <div className="icon-preview">
                                        <i className="material-icons">description</i>
                                        <h5>Resume</h5>
                                        <p className="center"><strong>Learn more about me:</strong> See my skills and experience summarized in one place by checking out my resume.</p>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                        <div className="col s12 m3 l3">
                            <div className="center home-icon">
                                <NavLink tag={Link} to="/code">
                                    <div className="icon-preview">
                                        <i className="material-icons">code</i>
                                        <h5>Code</h5>
                                        <p className="center"><strong>Check out my code:</strong> View my GitHub page to see my software development projects, including open-source libraries that you can use in your own work.</p>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                        <div className="col s12 m3 l3">
                            <div className="center home-icon">
                                <NavLink tag={Link} to="/contact-me">
                                    <div className="icon-preview">
                                        <i className="material-icons">message</i>
                                        <h5>Contact me</h5>
                                        <p className="center"><strong>Get in touch:</strong> Send me a message and let's start a conversation about your software development needs.</p>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </>
        );
    }
}
