import React, { Component } from 'react';
import { Work } from '../components/Work';
import { Education } from '../components/Education';

export class Resume extends Component {
    static displayName = Resume.name;

    constructor(props) {
        super(props);
        // this.state = { currentCount: 0 };
    }

    componentDidMount = () => window.scrollTo(0, 0);

    render() {
        return (
            <>
                <Work />
                <Education />
            </>
        );
    }

}
