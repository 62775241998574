import React, { Component } from 'react';
import M from 'materialize-css';
import { ProjectCard } from './ProjectCard';

export class Projects extends Component {
    static displayName = Projects.name;

    constructor(props) {
        super(props);
        this.state = {
            allItems: [],
            items: [],
            loading: true,
            searchTerm: null
        };

        this.drawElements = this.drawElements.bind(this);
        //this.handleSelectedProject = this.handleSelectedProject.bind(this);
        //this.handleOnCloseProject = this.handleOnCloseProject.bind(this);
        this.onChipsChanged = this.onChipsChanged.bind(this);
        this.filterByChips = this.filterByChips.bind(this);
    }

    async populateData(search) {
        const response = await fetch('resume/projects');
        const data = await response.json();

        const responseTechs = await fetch('resume/techs');
        const techs = await responseTechs.json();

        let filtered = [...data];
        if (search) {
            filtered = data.filter(i => {
                let searchLowerCase = search.toLowerCase();
                return i.id.toLowerCase().includes(searchLowerCase)
                    || i.name.toLowerCase().includes(searchLowerCase)
                    || i.text.some(t => t.toLowerCase().includes(searchLowerCase));
            });
        }

        this.setState({
            allItems: data,
            items: filtered,
            loading: false,
            searchTerm: search,
            techs: techs
        });
        this.initChips(search);
    }

    initChips(search) {

        let data = [];
        if (this.state.chips)
            data = this.state.chips;

        if (search)
            data.push({
                tag: search
            });

        let autocompleteItems = {};
        let temp = [...this.state.techs];
        for (var i = 0; i < temp.length; i++) {
            autocompleteItems[temp[i]] = null;
        }

        // init chips in input
        let elems = document.querySelectorAll('.chips');
        let instances = M.Chips.init(elems,
            {
                placeholder: 'Search. Ex: C#',
                secondaryPlaceholder: '+Other',
                limit: 5,
                autocompleteOptions: {
                    data: autocompleteItems,
                    limit: Infinity,
                    minLength: 1
                },
                data: data,
                onChipAdd: this.onChipsChanged,
                onChipDelete: this.onChipsChanged,
                onChipSelect: this.onChipsChanged
            });
    }

    filterByChips() {
        let elements = document.querySelectorAll('.chips');
        if (!elements)
            return;

        let instance = M.Chips.getInstance(elements[0]);

        let all = this.state.allItems;
        let filtered = [...all];

        let chips = instance.chipsData;
        if (chips && chips.length > 0) {
            filtered = all.filter(i => {
                return chips.some(
                    c => i.id.toLowerCase().includes(c.tag.toLowerCase())
                        || i.name.toLowerCase().includes(c.tag.toLowerCase())
                        || i.text.some(t => t.toLowerCase().includes(c.tag.toLowerCase()))
                );
            });
        }

        if (chips.length == 0)
            document.querySelector('.chips input').focus();

        this.setState({ items: filtered, chips: chips });
    }

    onChipsChanged() {
        this.filterByChips();
    }

    componentDidMount() {
        const queryParams = new URLSearchParams(this.props.location.search);
        const search = queryParams.get('search');
        console.log(`__search__: ${search}`);

        this.populateData(search);
        console.log('Mount');

        window.scrollTo(0, 0);
    }

    componentDidUpdate() {
        if (this.state.projectRecentlyClosed) {
            this.initChips();
            this.state.projectRecentlyClosed = false;
        }

        const queryParams = new URLSearchParams(this.props.location.search);
        const search = queryParams.get('search');
        if (search) {
            if (this.state.searchTerm != search) {
                console.log(`__search__: ${search}`);
                this.populateData(search);
            }
        }

        console.log('Update');
    }

    drawElements() {

        let searchAdvancedUI = (
            <>
                <div className="chips">
                </div>
            </>
        );

        if (!this.state.items || this.state.items.length == 0) {
            return (
                <>
                    <h3 className="header">Projects</h3>
                    <p>These are some of the main or interesting projects that I have anytime participated in.</p>

                    {searchAdvancedUI}

                    <div className="row">
                        <div className="col s12">
                            <p>Sorry, there is no data to display with the selected filter. </p>
                            <p>Please try selecting a different filter or check back later for updates.</p>
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <h3 className="header">Projects</h3>
                    <p>These are some of the main or interesting projects that I have anytime participated in.</p>

                    {searchAdvancedUI}

                    <div className="row">
                        {this.state.items.map((item, index) => {
                            let id = `card_${index}`;
                            return (
                                <div key={id} className="col s12 l4 m6">
                                    <ProjectCard id={id} item={item} />
                                </div>
                            );
                        }
                        )}
                    </div>
                </>
            );
        }
    }

    render() {

        if (this.state.loading)
            return (
                <p><em>Loading...</em></p>
            );

        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.drawElements();

        return (
            <div>
                {contents}
            </div>
        );
    }

}

