import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import M from 'materialize-css';
import me from '../imgs/me-03-small.jpg'
//import background from '../imgs/background.jpg'
import background from '../imgs/bg-00.png'
import LineIcon from 'react-lineicons';
import { Links } from '../components/Links';
import { theme } from '../js/theme';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.setTheme = this.setTheme.bind(this);
        this.state = {
            collapsed: true
        };
    }

    setTheme() {
        let schema = theme.getTheme();
        if (schema == 'light')
            schema = 'dark';
        else
            schema = 'light';

        theme.setTheme(schema, true);
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    componentDidMount() {
        // this.initSideNav();
    }

    componentDidUpdate() {
        var elems = document.querySelectorAll('.sidenav');
        M.Sidenav.init(elems);
    }

    initSideNav() {
        document.addEventListener('DOMContentLoaded', function () {
            var elems = document.querySelectorAll('.sidenav');
            M.Sidenav.init(elems);
        });
    }

    render() {
        return (
            <header>
                <div className="navbar-fixed">
                    <nav>
                        <div className="nav-wrapper">
                            <NavLink tag={Link} to="/" className="brand-logo">
                                <div className="show-on-large hide-on-med-and-down truncate">Giovanny Farto M.</div>
                                <div className="show-on-medium-and-down hide-on-large-only text-sm truncate">Giovanny Farto</div>
                            </NavLink>
                            <a href="#!" data-target="mobile" className="sidenav-trigger"><i className="material-icons">menu</i></a>
                            <ul className="right hide-on-med-and-down">
                                <li><NavLink tag={Link} to="/">About</NavLink></li>
                                <li><NavLink tag={Link} to="/resume">Resume</NavLink></li>
                                <li><NavLink tag={Link} to="/projects">Projects</NavLink></li>
                                <li><NavLink tag={Link} to="/code">Code</NavLink></li>
                                <li><NavLink tag={Link} to="/contact-me"><LineIcon name="envelope" /></NavLink></li>
                                {/*<li><NavLink tag={Link} to="/code"><LineIcon name="code" /></NavLink></li>*/}
                                <li><a onClick={this.setTheme} ><LineIcon id="theme-btn" name="sun" /></a></li>
                            </ul>
                            <a className="right sidenav-trigger show-on-medium-and-down hide-on-large-only" onClick={this.setTheme} ><LineIcon id="theme-btn-movil" name="sun" /></a>
                        </div>
                    </nav>
                </div>

                {/*<div className="container">*/}
                {/*    <a href="#" data-target="slide-out" className="top-nav sidenav-trigger full hide-on-large-only"><i className="material-icons">menu</i></a>*/}
                {/*</div>*/}

                {/*<ul id="slide-out" className="sidenav sidenav-fixed">*/}
                {/*    <li><div className="user-view">*/}
                {/*        <div className="background">*/}
                {/*            <img src={background} />*/}
                {/*        </div>*/}
                {/*        <a href="#user"><img className="circle" src={me} /></a>*/}
                {/*        <a href="#name"><span className="white-text name">John Doe</span></a>*/}
                {/*        <a href="#email"><span className="white-text email">jdandturk@gmail.com</span></a>*/}
                {/*    </div></li>*/}
                {/*    <li><a href="#!"><i className="material-icons">cloud</i>First Link With Icon</a></li>*/}
                {/*    <li><a href="#!">Second Link</a></li>*/}
                {/*    <li><div className="divider"></div></li>*/}
                {/*    <li><a className="subheader">Subheader</a></li>*/}
                {/*    <li><a className="waves-effect" href="#!">Third Link With Waves</a></li>*/}
                {/*</ul>*/}

                <ul className="sidenav" id="mobile">
                    <li><div className="user-view">
                        <div className="background">
                            <img src={background} />
                        </div>
                        <a href="#user"><img className="circle z-depth-3" src={me} /></a>
                        <a href="#name"><span className="white-text name">Giovanny Farto</span></a>
                        <span className="white-text email">C# Developer</span>
                        {/*<a href="#email"><span className="white-text email">jdandturk@gmail.com</span></a>*/}

                        <Links />

                        <br />
                    </div></li>
                    <li><NavLink className="sidenav-close" tag={Link} to="/">About</NavLink></li>
                    <li><NavLink className="sidenav-close" tag={Link} to="/resume">Resume</NavLink></li>
                    <li><NavLink className="sidenav-close" tag={Link} to="/projects">Projects</NavLink></li>
                    <li><NavLink className="sidenav-close" tag={Link} to="/code">Code</NavLink></li>
                    <li><NavLink className="sidenav-close" tag={Link} to="/contact-me">Contact</NavLink></li>
                </ul>
            </header>
        );
    }
}
