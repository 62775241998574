import { gf } from './storage';

export const theme = {
    onChange: null,
    getTheme: function () {

        let theme = gf.getTheme();
        if (!theme) {
            theme = "light";
            this.setTheme(theme, false);
        }

        return theme;
    },
    wasSet: function () {

        let custom = gf.getThemeCustomized();
        if (!custom) {
            return false;
        }

        return custom == 'user-selected';
    },
    init: function () {
        // remove previous theme
        let current = gf.getTheme();
        let root = document.getElementsByTagName('html')[0];
        if (!current) {
            current = 'light';
        }

        // add new theme
        if (!document.body.classList.contains(current))
            document.body.classList.add(current);
        if (!root.classList.contains(current))
            root.classList.add(current);

        let btn = document.getElementById('theme-btn');
        if (btn) {
            if (current == 'dark')
                btn.classList = 'lni lni-sun size-md';
            else
                btn.classList = 'lni lni-night size-md';
        }
        let btnMovil = document.getElementById('theme-btn-movil');
        if (btnMovil) {
            if (current == 'dark')
                btnMovil.classList = 'lni lni-sun size-md';
            else
                btnMovil.classList = 'lni lni-night size-md';
        }
    },
    apply: function (value) {
        if (!value)
            value = 'light';

        // remove previous theme
        let current = gf.getTheme();
        let root = document.getElementsByTagName('html')[0];
        if (current) {
            if (document.body.classList.contains(current))
                document.body.classList.remove(current);
            if (root.classList.contains(current))
                root.classList.remove(current);
        }

        // add new theme
        document.body.classList.add(value);
        root.classList.add(value);

        let btn = document.getElementById('theme-btn');
        if (btn) {
            if (value == 'dark')
                btn.classList = 'lni lni-sun size-md';
            else
                btn.classList = 'lni lni-night size-md';
        }
        let btnMovil = document.getElementById('theme-btn-movil');
        if (btnMovil) {
            if (value == 'dark')
                btnMovil.classList = 'lni lni-sun size-md';
            else
                btnMovil.classList = 'lni lni-night size-md';
        }
    },
    setTheme: function (value, custom) {
        this.apply(value);
        gf.setTheme(value);
        if (this.onChange) {
            this.onChange();
        }
        if (custom) {
            gf.setThemeCustomized('user-selected');
        }
    }//,
    //setColorScheme: function (scheme) {
    //    console.log('dark');
    //    this.setTheme(scheme, false);
    //},
    //getPreferredColorScheme: function () {
    //    if (window.matchMedia) {
    //        if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
    //            return 'dark';
    //        } else {
    //            return 'light';
    //        }
    //    }
    //    return 'light';
    //},
    //updateColorScheme: function () {
    //    setColorScheme(getPreferredColorScheme());
    //}
};

//if (window.matchMedia) {
//    var colorSchemeQuery = window.matchMedia('(prefers-color-scheme: dark)');
//    colorSchemeQuery.addEventListener('change', gf.updateColorScheme);
//}

//gf.updateColorScheme();

