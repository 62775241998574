import React, { Component } from 'react';
import M from 'materialize-css';

export class Work extends Component {
    static displayName = Work.name;

    constructor(props) {
        super(props);
        this.state = { items: [], loading: true };
        //this.drawFunctions = this.drawFunctions.bind(this);
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : Work.drawData(this.state.items);

        return (
            <div>
                <h3 className="header">Work experience</h3>
                {/*<p>This is my work experience page.</p>*/}
                {contents}
            </div>
        );
    }

    componentDidMount() {
        this.populateData();
    }

    componentDidUpdate() {
        let elems = document.querySelectorAll('.collapsible');
        M.Collapsible.init(elems);

        window.scrollTo(0, 0);
    }

    static drawData(items) {
        return (
            <ul className="collapsible">
                {items.map((item, index) =>
                    <li key={index}>
                        <div className="collapsible-header" key={index}>
                            <strong className="light-blue-text">{item.title}</strong>
                            <span className="blue-grey-text text-lighten-2 hide-on-small-only">
                                &nbsp;&nbsp; | &nbsp;&nbsp;
                                {item.companyName}
                                &nbsp;&nbsp;
                                (&nbsp;{item.period}&nbsp;)
                            </span>
                        </div>
                        <div className="collapsible-body">
                            <div className="show-on-small">
                                <h6 className="center-align">
                                    {item.companyName}
                                </h6>
                                <br />
                                <span className="center-align">
                                    (&nbsp;{item.period}&nbsp;)
                                </span>
                                <br />
                                <br />
                            </div>
                            <span>{this.drawFunctions(item.functions, index)}</span>
                        </div>
                    </li>
                )}
            </ul>
        );
    }

    static drawFunctions(funs, index) {
        return (
            <>
                <code key={`fun_${index}`}>
                    {funs.map((item, i2) => <div key={`code_${index}_${i2}`}>{item} <br /></div>)}
                </code>
            </>
        );
    }

    static drawFunctions_old_2(funs) {
        return (
            <ul>
                {funs.map(function (item) {
                    if (item.startsWith("//")) {
                        return <li key={item}><h5>{item}</h5></li>
                    } else if (item.startsWith("--")) {
                        return <li key={item}><blockquote>{item}</blockquote></li>
                    } else if (item.startsWith("<ln>")) {
                        return <li key={item}>...</li>
                    } else {
                        return <li key={item}>{item}</li>
                    }
                })}
            </ul>
        );
    }

    static drawFunctions_old(funs) {
        return (
            <ul>
                {funs.map(item =>
                    <li key={item}>
                        {item}
                    </li>
                )}
            </ul>
        );
    }

    async populateData() {
        const response = await fetch('resume/work');
        const data = await response.json();
        this.setState({ items: data, loading: false });
    }
}
