import React, { Component } from 'react';
import LineIcon from 'react-lineicons';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

export class Links extends Component {
    static displayName = Links.name;

    render() {
        return this.getLinks3();
    }

    getLinks3() {
        return (
            <>
                <div className="center-align">
                    <a href="https://www.linkedin.com/in/giovanny-farto/" target="_blank" className="hoverable z-depth-3 btn-floating btn-small light-blue darken-4"><LineIcon name="linkedin" /></a>
                    &nbsp;&nbsp;&nbsp;
                    <a href="https://stackoverflow.com/users/2472664/giovanny-farto-m" target="_blank" className="hoverable z-depth-3 btn-floating btn-small amber accent-4"><LineIcon name="stackoverflow" /></a>
                    &nbsp;&nbsp;&nbsp;
                    <NavLink tag={Link} to="/contact-me" className="hoverable z-depth-3 btn-floating btn-small blue-grey darken-2"><LineIcon name="envelope" /></NavLink>
                    &nbsp;&nbsp;&nbsp;
                    {/*<a href="https://twitter.com/GiovannyFarto" target="_blank" className="hoverable z-depth-3 btn-floating btn-small light-blue lighten-1"><LineIcon name="twitter" /></a>*/}
                    {/*&nbsp;&nbsp;&nbsp;*/}
                    <a href="https://www.instagram.com/gfarto/" target="_blank" className="hoverable z-depth-3 btn-floating btn-small purple accent-2"><LineIcon name="instagram" /></a>
                    &nbsp;&nbsp;&nbsp;
                    <a href="https://github.com/gfarto" target="_blank" className="hoverable z-depth-3 btn-floating btn-small blue darken-1"><LineIcon name="github" /></a>
                </div>
            </>
        );
    }

    getLinks2() {
        return (
            <>
                <div className="collection">
                    <a href="#!" className="collection-item"><LineIcon name="linkedin" /> On LinkedIn</a>
                    <a href="#!" className="collection-item"><LineIcon name="stackoverflow" /> On Stack Overflow</a>
                    <a href="#!" className="collection-item"><LineIcon name="envelope" /> Send me a message</a>
                    <a href="#!" className="collection-item"><LineIcon name="twitter" /> On Twitter - @giovanny.farto</a>
                    <a href="#!" className="collection-item"><LineIcon name="instagram" /> On Instagram - @gfarto</a>
                </div>
            </>
        );
    }

    getLinks() {
        return (
            <>
                <ul className="collection with-header">
                    <li className="collection-header"><h6>Contact me</h6></li>
                    <li className="collection-item"><div><a href="#!" className="secondary-content"><LineIcon name="linkedin" /></a>On LinkedIn</div></li>
                    <li className="collection-item"><div>On Stack Overflow<a href="#!" className="secondary-content"><LineIcon name="stackoverflow" /></a></div></li>
                    <li className="collection-item"><div>Send me a message<a href="#!" className="secondary-content"><LineIcon name="envelope" /></a></div></li>
                    <li className="collection-item"><div>On Twitter - @giovanny.farto<a href="#!" className="secondary-content"><LineIcon name="twitter" /></a></div></li>
                    <li className="collection-item"><div>On Instagram - @gfarto<a href="#!" className="secondary-content"><LineIcon name="instagram" /></a></div></li>
                </ul>
            </>
        );
    }
}