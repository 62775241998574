import React, { Component } from 'react';

export class Summary extends Component {
    static displayName = Summary.name;

    componentDidMount = () => window.scrollTo(0, 0);

    render() {
        return (
            <div>
                <h3 className="header">Summary</h3>
                <p className="flow-text">
                    With over 15 years of experience in analysis, design, and development of software applications, I specialize in creating Windows Forms, web applications, and web services using C#.
                </p>
                <p className="flow-text">
                    I am also an expert in designing and implementing data exchange solutions using http, AS2, and sftp protocols, which I have applied in international projects with esteemed clients such as Roche, GSK, Schering-Plough, Boehringer Ingelheim, and Acino.
                </p>
                <p className="flow-text">
                    I have extensive functional domain experience in logistics and warehouse management systems, particularly in the pharmaceutical sector, which has given me the opportunity to understand the unique requirements of this industry.
                </p>
                {/*<p className="flow-text">*/}
                {/*    In addition, I have more than 5 years of experience in designing and developing native Android and Windows Mobile (UWP) applications for a variety of use cases. With my diverse skill set and industry knowledge, I am confident that I can help you achieve your software development goals.*/}
                {/*</p>*/}

                <p className="flow-text">
                    As an experienced C# developer, I specialize in creating high-quality Windows, Web, and Mobile applications. With a focus on delivering solutions that meet the needs of my clients, I have a proven track record of developing effective software solutions.
                </p>

                <p className="flow-text">
                    In addition to my expertise in C#, I also have experience in Android development using Java and Kotlin. This diverse skill set allows me to develop solutions across multiple platforms and technologies.
                </p>

                <p className="flow-text">
                    Whether you're looking to develop a new application or optimize an existing one, I have the skills and experience to help you achieve your goals.
                </p>

            </div>
        );
    }

    render_backup() {
        return (
            <div>
                <h3 className="header">Summary</h3>
                <p className="flow-text">
                    15+ years of experience in analysis, design and development of client/server, web based and n-tier applications. Expert in developing Windows Forms applications, web applications and web services with C#.
                </p>
                <p className="flow-text">
                    Expertise in designing and developing solutions for data exchange through iDoc messages using http, AS2 and sftp protocols. This experience has been applied in international projects with companies like Roche, GSK, Schering-Plough, Boehringer Ingelheim, Acino, among others.
                </p>
                <p className="flow-text">
                    Functional domain experience in logistics and warehouse management systems, especially for the pharmaceutical sector.
                </p>
                <p className="flow-text">
                    Also, 5+ years of experience in design and development of native Android and Windows Mobile (UWP) applications for different areas.
                </p>

            </div>
        );
    }
}