import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

export class TechChips extends Component {
    static displayName = TechChips.name;

    constructor(props) {
        super(props);
        this.state = { items: [], loading: true };
    }

    componentDidMount() {
        this.populateData();
    }

    async populateData() {
        const response = await fetch('resume/techs');
        const data = await response.json();
        this.setState({ items: data, loading: false });
    }

    static drawData(items) {
        return items.map((item, index) => {
            return (
                <div className="chip chip-tech" key={`chip_${index}`}>
                    <NavLink tag={Link} to={"/projects?search=" + item.replace('#', '%23')}>{item}</NavLink>
                    {/*<Link className="white-text" to={"/projects?search=" + item.replace('#', '%23')}>{item}</Link>*/}
                </div>
            );
        });
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : TechChips.drawData(this.state.items);

        return (
            <div className="row">
                <div className="col s8 push-s2 pull-s2 center">
                    {contents}
                </div>
            </div>
        );
    }
}