import React, { Component } from 'react';
// import M from 'materialize-css';

export class Education extends Component {
    static displayName = Education.name;

    constructor(props) {
        super(props);
        this.state = { items: [], loading: true };
    }

    async populateData() {
        const response = await fetch('resume/education');
        const data = await response.json();
        this.setState({ items: data, loading: false });
    }

    componentDidMount() {
        this.populateData();
    }

    componentDidUpdate() {
        //    let elems = document.querySelectorAll('.collapsible');
        //    M.Collapsible.init(elems);
    }

    static drawData(items) {
        return (
            <ul className="collection">
                {items.map(item =>
                    <li key={item.order} className="collection-item">
                        <span className="title">
                            <strong className="blue-text">{item.title}</strong>
                        </span>
                        <p>
                            {item.institute}
                            <br />
                            {item.when}
                        </p>
                    </li>
                )}
            </ul>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : Education.drawData(this.state.items);

        return (
            <div>
                <h3 className="header">Education</h3>
                {/*<p>This is my work education page.</p>*/}
                {contents}
            </div>
        );
    }

}
