import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';

import { Home } from './components/Home';
import { Projects } from './components/Projects';
import { Project } from './components/Project';
import { Resume } from './components/Resume';
import { ContactMe } from './components/ContactMe';
import { Code } from './components/Code';

import './custom.css';

export default class App extends Component {
    static displayName = App.name;

    //constructor(props) {
    //    super(props)
    //    this.childDiv = React.createRef()
    //}

    //componentDidMount = () => this.handleScroll()

    //componentDidUpdate = () => this.handleScroll()

    //handleScroll = () => {
    //    const { index, selected } = this.props
    //    if (index === selected) {
    //        setTimeout(() => {
    //            window.scrollTo(0, 0);
    //            //this.childDiv.current.scrollIntoView({ behavior: 'smooth' })
    //        }, 500)
    //    }
    //}

    render() {
        return (
            <Layout>
                <Route exact path='/' component={Home} />
                <Route path='/projects' component={Projects} />
                <Route path='/project' component={Project} />
                <Route path='/resume' component={Resume} />
                <Route path='/contact-me' component={ContactMe} />
                <Route path='/code' component={Code} />
            </Layout>
        );
    }
}
