import React, { Component } from 'react';
// import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
// import me from './../imgs/me.jpg';
import M from 'materialize-css';
import { BasicData } from './BasicData';
import { Links } from './Links';
import { TechChips } from './TechChips';
/*import { Modal } from './Modal';*/
import { theme } from '../js/theme';

export class Layout extends Component {
    static displayName = Layout.name;

    componentDidMount() {
        document.addEventListener('DOMContentLoaded', function () {
            var elems = document.querySelectorAll('.sidenav');
            M.Sidenav.init(elems);
        });

        theme.init();
    }

    render() {
        return (
            <>

                <NavMenu />

                <main className="app">
                    <div className="section">

                        {/*<Modal />*/}

                        <div>
                            <div className="row">
                                <div className="col s12 m12 l3">
                                    <BasicData />
                                    <div className="hide-on-med-and-down">
                                        <Links />
                                    </div>
                                    <div className="hide-on-med-and-down">
                                        <br />
                                        <br />
                                        <TechChips />
                                    </div>
                                </div>
                                <div className="col s12 m12 l9">
                                    {this.props.children}
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

                <footer className="page-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col l6 s12">
                                <h5 className="white-text">About this web site</h5>
                                <p className="grey-text text-lighten-4">The web site was developed using a combination of 
                                    <a href="https://dotnet.microsoft.com/en-us/languages/csharp" target="_blank"> C# </a>
                                    for the back-end controllers and <a href="https://react.dev/" target="_blank"> ReactJS</a>,
                                    <a href="https://developer.mozilla.org/en-US/docs/Web/javascript" target="_blank"> JavaScript </a> and
                                    <a href="https://materializecss.com/" target="_blank"> Materialize </a> for the front-end.</p>
                            </div>
                            <div className="col l4 offset-l2 s12">
                                <h5 className="white-text">Links</h5>
                                <ul>
                                    <li><a className="grey-text text-lighten-3" href="https://www.linkedin.com/in/giovanny-farto/" target="_blank">My Linkedin Profile</a></li>
                                    <li><a className="grey-text text-lighten-3" href="https://stackoverflow.com/users/2472664/giovanny-farto-m" target="_blank">My StackoverFlow Profile</a></li>
                                    <li><a className="grey-text text-lighten-3" href="https://twitter.com/GiovannyFarto" target="_blank">My Twitter</a></li>
                                    <li><a className="grey-text text-lighten-3" href="https://www.instagram.com/gfarto/" target="_blank">My Instagram</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="footer-copyright">
                        <div className="container">
                            Designed and Developed by Giovanny Farto
                            {/*<a className="grey-text text-lighten-4 right" href="#!">More Links</a>*/}
                        </div>
                    </div>
                </footer>
            </>
        );
    }

    //    render() {
    //        return (
    //            <div>
    //                <NavMenu />
    //                <div className="container">
    //                    <Container>
    //                        {this.props.children}
    //                    </Container>
    //                </div>
    //            </div>
    //        );
    //    }
}
