import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

export class Code extends Component {
    static displayName = Code.name;

    componentDidMount = () => window.scrollTo(0, 0);

    render() {
        return (
            <>
                <div className="row">
                    <div className="col s12">
                        <h3>Code</h3>

                        <p>Sorry for this!</p>
                        <p>I'm still preparing the projects to upload them to the final repo.</p>

                        <p>Meanwhile, you can continue checking my <NavLink tag={Link} to="/projects">projects</NavLink> or send me a <NavLink tag={Link} to="/contact-me">message</NavLink>.</p>
                    </div>
                </div>
            </>
        );
    }
}
